import axios from 'axios'
import BASE_URL from './Enviroment' 

const instance = axios.create({
   baseURL:  BASE_URL
 });

/*function getInstance () {
  
   if (sessionStorage.getItem('userData')) {
      const itemStorage = JSON.parse(sessionStorage.getItem('userData'))
      const AUTH_TOKEN = itemStorage.data.token
      instance.defaults.headers.common = {'Authorization': `Bearer ${AUTH_TOKEN}`}
      
   }
   return instance
 } */



const apis ={
 
      index:(endpoint)=>instance.get(endpoint),
      store:(endpoint,data)=>instance.post(endpoint, data),
      show:(endpoint, id)=>instance.get(`/${endpoint}/${id}`),
      update:(endpoint, id, data)=>instance.put(`/${endpoint}/${id}`, data),
      delete:(endpoint, id)=>instance.delete(`/${endpoint}/${id}`),
      uploadFile:(file, config)=>instance.post(`/files`, file, config),
      login:(user)=>instance.post(`/login`, user),
      
      
}

export default apis