
import React, {useState, useEffect} from 'react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {Link} from 'react-router-dom'
import Layout from '../../components/layout'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Api from './../../services/Api'
import BASE_URL_UPLOAD from './../../services/EnvUpload'


function Posts() {

  const [posts, setPosts] = useState([])
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    getPosts()
  },[success]);


  async function getPosts() {
    const response = await Api.index('posts')
    setPosts(response.data)
  }
 
   async function deletePost(id){    
  
    try {
      await Api.delete('posts', id)
      toast.success("Post excluido com sucesso!");
      setSuccess(true)
    } catch (error) {
      toast.error("Não foi possível excluir o post!");
    }

}

  function ButtonDelete  (cell, row, pageLink) {
    
    return <button onClick={()=>{deletePost(row.id)}} type="button" 
    className={`btn btn-danger`}>
    <i className="fa fa-times-circle "></i> Excluir</button>
 }

 function ButtonEdit  (cell, row, pageLink) {
 
  let disableButton = null
  let link = `/${pageLink}/edit/`  
   return <Link to={link + row['id']}    
    type="button" className={`btn btn btn-info ${disableButton}`}><i className="fa fa-chevron-circle-down"></i> Editar</Link>
 }


 function buttoShowFile(cell, row){
  let disableButton = (row['file_name'])? null: 'disabled'
  let link = BASE_URL_UPLOAD + row['file_name']

   return <a target="_blank" rel="noopener noreferrer" href={link}    
    type="button" className={`btn btn btn-info ${disableButton}`}>
    <i className="fa fa-file"></i> Arquivo</a>
 }

  return (

        <Layout pageName="Posts">

        <ToastContainer  />

        <Link  className="btn btn-info btn-fill" to="/posts/novo">Cadastrar</Link>
        
              <BootstrapTable
                  options={{noDataText:'Não existem posts cadastrados'}}
                  data={posts}
                  pagination={ true }
                  searchPlaceholder={"Filtrar"}
                  search={ true }>

                <TableHeaderColumn isKey={true} dataField='id'>Id</TableHeaderColumn>
                <TableHeaderColumn  dataField='post_title'>Título do Post</TableHeaderColumn>

                <TableHeaderColumn  
                  dataField='button' 
                  dataFormat={buttoShowFile}>Arquivo
                </TableHeaderColumn>

                <TableHeaderColumn  
                  dataField='button' 
                  formatExtraData={'posts'} 
                  dataFormat={ButtonEdit}>Editar
                </TableHeaderColumn>

      
                <TableHeaderColumn  
                dataField='button' 
                dataFormat={ButtonDelete}>Excluir
             
                </TableHeaderColumn>
                  
  
               </BootstrapTable>
        
        </Layout>

  )
}

export default Posts