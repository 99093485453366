
import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Layout from './../../components/layout'
import ButtonEdit from './../../components/ButtonEdit'
import Api from './../../services/Api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Categories() {

  const [categories, setCategories] = useState([])
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    getCategories()
  
  },[success]);

  async function getCategories(){
    const response = await Api.index('categories')
    setCategories(response.data)
  }

  async function deleteCategory(id){    
    try {
      await Api.delete('categories', id)
      toast.success("Categoria Excluída com Sucesso !");
      setSuccess(true)
    } catch (error) {
      toast.error("Erro - Existe publicação vinculada essa categoria !");
    }
       
  }

  function ButtonDelete  (cell, row) {

    return <button onClick={()=>{deleteCategory(row.id)}} type="button" 
    className={`btn btn-danger`}>
    <i className="fa fa-times-circle "></i> Excluir</button>
   }
 
  return (

        <Layout pageName="Categorias">

        <ToastContainer  />

        <Link  className="btn btn-info btn-fill" to="/categorias/nova">Cadastrar</Link>
        
            <BootstrapTable  useRef='table' 
              options={{noDataText:'Não existem categorias cadastradas'}}
              data={categories}
              pagination={ true }
              searchPlaceholder={"Filtrar"}
              search={ true }>

              <TableHeaderColumn  isKey={true} dataField='id'>Id</TableHeaderColumn>
              <TableHeaderColumn  dataField='category_title'>Nome da Categoria</TableHeaderColumn>
              
              <TableHeaderColumn  
                  dataField='button' 
                  formatExtraData={'categorias'} 
                  dataFormat={ButtonEdit} >Editar
              </TableHeaderColumn>
              
                <TableHeaderColumn  
                dataField='button' 
                dataFormat={ButtonDelete} >Excluir
                </TableHeaderColumn>
            
                
              </BootstrapTable>
        
        </Layout>

  )
}


export default Categories

