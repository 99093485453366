import React, {useState} from 'react'
import {ProgressBar , Modal , Button } from 'react-bootstrap';
import Api from './../../services/Api'
import Alert from 'react-bootstrap/Alert'


function Upload (props){

    const [showModal, setShowModal] = useState(false)
    const [progress, setProgress] = useState(0)
    const [fileSelected, setFileSelected] = useState(null)
    const [success, setSuccess] = useState(false)


      function handleClose() {
        setShowModal(false)
        setSuccess(false)
      }
    
      function handleShow() {
        setProgress(0)
        setShowModal(true)
      }
  
      function fileSelectedHandler  (event) {
        setFileSelected(event.target.files[0])
     }

     function fileUploadHandler  () {
      console.log('arquivo1', fileSelected.name)
      let formData = new FormData();
      formData.append('anexo', fileSelected, fileSelected.name)
      console.log('Arquivo', formData)
      uploadFile(formData)
   }


      function uploadFile(file) {

        const config = {onUploadProgress: (ProgressEvent) => {
              let progressData = 0;
              progressData = Math.round((ProgressEvent.loaded ) / ProgressEvent.total * 100)
              setProgress(progressData)
            }
          }
            
        Api.uploadFile(file, config)
          
            .then((res) => {
              setSuccess(true)  
            })
            .catch((err) => {
              console.log(err)
          
            })
          
      }
  

  
      return(
    
        <div>

              <Button className="btn btn-info btn-fill" variant="primary" onClick={handleShow}>Anexar Arquivo</Button>
          
                <Modal show={showModal} onHide={handleClose}>
                    
                    <Modal.Header closeButton>
                       <Modal.Title>Anexo de Arquivo</Modal.Title>
                       
                    </Modal.Header>

                    <div className="row">
                      <div className="col-sm-11 ml-4"> 
                        {
                          (progress > 0) && <ProgressBar now={progress} label={`${progress}%`} />
                        }
                    </div>
                   </div>
               
                    <Modal.Body>
                        <input name="anexo" type="file" onChange={fileSelectedHandler}></input>
                        

                    <div className="row">
                        <div className="col-sm-12"> 
                            <Button id="btn-send-file"  className="btn btn-info btn-fill" variant="primary" 
                            onClick={fileUploadHandler}>Enviar</Button>
                        </div>
                    </div>

                  

                    </Modal.Body>
                    
                    <Modal.Footer>

                    {
                      success&& 
                      <Alert closeLabel="Fechar"  variant="success">
                          Arquivo enviado com Sucesso !!
                      </Alert>
                     }
      

                      {
                        (progress === 100) && 
                        <button id="btn-close-modal" className="btn btn-info waves-effect waves-light pull-right" 
                        onClick={handleClose}>Fechar</button>
                      }
                        
                    </Modal.Footer>
                
                </Modal>
          
          </div>
          
        )
    }


export default Upload





