import React, { useState, useEffect} from 'react'
import {  Redirect } from 'react-router'
import Logo from './../../assets/img/logo-origin.png'
import Api from './../../services/Api'
import Spinner from 'react-bootstrap/Spinner'
import * as Yup from 'yup'

function Login (props){
    const [pathlocation, setPathLocation] = useState( props.location.pathname)
    const [user, setUser] = useState({email: '',password:''})
    const [isLogged, setIsLogged] = useState(false)
    const [provider, setProvider] = useState(0)
    const [validation, setValidation]= useState([])
    const [loading, setLoading] = useState(null)

    useEffect(() => {
       checkIsLogged(pathlocation)
    },[pathlocation])

   
  
    function checkIsLogged(pathlocation){

        if(pathlocation  === "/logout"){
            sessionStorage.clear()
            setIsLogged(false)
            setPathLocation('/login')
        } else{
            if(sessionStorage.getItem('userData')){
                const userData = JSON.parse(sessionStorage.getItem('userData'))
                setProvider(userData.data.user.provider)
                setIsLogged(true)
            }
        }
    }

    async function login(){
  
        try {
          
          setLoading(true)
          const response = await Api.store('login', user)
          if(response){
            sessionStorage.setItem('userData', JSON.stringify(response))
            setIsLogged(true)
          } 
         
        } catch (error) {
            setLoading(false)

        }
    }

  
   async function handleSumit (event) {  
        event.preventDefault();
     
        try {
            
            const schema = Yup.object().shape({
                    email: Yup.string().required('Nome de usuário é obrigatório'),
                    password:Yup.string().required('Senha é obrigatório')
            })
             
            await schema.validate(user, {
               abortEarly :false
            })
            login()

        } catch (error) {
            setValidation(error.inner)
        }


     }

    function handleForm(e){
        const { name, value } = e.target;
        setUser(prevPost => ({
            ...prevPost,
            [name]: value
        }));      
    }

 

   function loginError(){
        if(validation.length > 0){

        const listItems = validation.map((item) =>
            <li>{item.message}</li>
          );

            return (

                <div className="alert alert-danger" role="alert">
                    <ul>{listItems}</ul>
                
                </div>
                
            ) 
             
       }
    }

  if (isLogged && provider === 0) {

        return (
            <Redirect to="/dashboard"/>
        )
        } else {

       return(

        <div className="bg-login">    
                <div className="container">
                    <div className="login_wrapper">
                        <div className="animate form login_form">
                            <div className="login_content">
                    
                            <h1>
                                <img alt="logo" className="logo-login" src={Logo}/>
                            
                            </h1>
                    
                                <form name="contactform" className="contactform" onSubmit={handleSumit}>
                                <input  name="email" type="text" onChange={handleForm}  className="form-control" placeholder="Email"></input>
                                <input name="password" type="password"  onChange={handleForm}  className="form-control" placeholder="Senha" ></input>
                                
                                <div className="row">
                                    <div className="col-md-12">
                                        <input type="submit" className="btn  btn-danger submit-login" value="Acessar"></input>
                                    </div>
                                </div>
                                {loginError()} 

                           
                                <div className="d-flex justify-content-center">
                                     {loading && <Spinner animation="border" />}
                                </div>
                             
                        
                                </form>
                    
                            </div>
                        </div>
                            
                    </div>

                    
                </div>
                <span className="version">Versão Beta</span>
            </div>

        
         )}
    
   
}
    

export default (Login)

