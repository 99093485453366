
import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Layout from './../../components/layout'
import Api from './../../services/Api'


function Categories() {

  const [categories, setCategories] = useState([])


  useEffect(() => {
    getCategories()
  },[]);

  async function getCategories(){
    const response = await Api.index('categories')
    setCategories(response.data)
  }

  function ButtonPosts (cell, row, pageLink) {
 
    let disableButton = null
    let link = `/${pageLink}/categorias/`  
     return <Link to={link + row['id']}    
      type="button" className={`btn btn btn-info ${disableButton}`}>
      <i className="fa fa-chevron-circle-down"></i> Vê Publicações</Link>
   }



  return (

        <Layout pageName="Categorias">  
        
            <BootstrapTable  useRef='table' 
              options={{noDataText:'Não existem categorias cadastradas'}}
              data={categories}
              pagination={ true }
              searchPlaceholder={"Filtrar"}
              search={ true }>

              <TableHeaderColumn  isKey={true} dataField='id'>Id</TableHeaderColumn>
              <TableHeaderColumn  dataField='category_title'>Nome da Categoria</TableHeaderColumn>
              
              <TableHeaderColumn  
                  dataField='button' 
                  dataAlign='center' 
                  formatExtraData={'posts'} 
                  dataFormat={ButtonPosts} >
              </TableHeaderColumn>
              
              
            
              </BootstrapTable>
        
        </Layout>

  )
}


export default Categories

