
import React, {useState, useEffect} from 'react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {Link} from 'react-router-dom'
import Layout from '../../components/layout'
import Api from './../../services/Api'


function PostsShowClient(props) {

  const [posts, setPosts] = useState([])
  const id = props.match.params.id

  useEffect(() => {
    getPosts(id)
  },[id]);

  async function getPosts(id) {
    const response = await Api.show('posts-category', id)
    setPosts(response.data)
  }

  function ButtonDownload (cell, row, pageLink) {
    let disableButton = null
    let link = `/${pageLink}/single/`  
    return <Link to={link + row['id_post']} type="button" className={`btn btn btn-info ${disableButton}`}>
    <i className="fa fa-chevron-circle-down"></i> Ver Publicação</Link>
   }
 

   

  return (

        <Layout pageName="Arquivos">

              <BootstrapTable
                options={{noDataText:'Não existem posts cadastrados'}}
                data={posts}
                pagination={ true }
                searchPlaceholder={"Filtrar"}
                search={ true }>

                <TableHeaderColumn isKey={true} dataField='id_post'>Id</TableHeaderColumn>
                <TableHeaderColumn  dataField='post_title'>Título do Post</TableHeaderColumn>
                <TableHeaderColumn  
                dataField='button' 
                dataAlign='center' 
                formatExtraData={'posts'} 
                dataFormat={ButtonDownload} >
            </TableHeaderColumn>
          
               </BootstrapTable>
        
        </Layout>

  )
}

export default PostsShowClient