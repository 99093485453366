
import React from 'react'
import {Link} from 'react-router-dom'
import './../../assets/css/bootstrap.min.css'
import './../../assets/css/light-bootstrap-dashboard.css?v=2.0.0'
import './../../assets/css/demo.css'

function NavbarTop(props) {

  return (
  
    <nav className="navbar navbar-expand-lg " color-on-scroll="500">
    <div className="container-fluid">
       
        <button  className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" 
        aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navigation">
           
            <ul className="navbar-nav ml-auto">
                
                <li className="nav-item">
                    <Link className="nav-link" to="/logout">
                        <span className="no-icon">Sair do Sistema</span>
                    </Link>
                </li>
            </ul>
        </div>
    </div>
</nav>
  )
}

export default  NavbarTop



