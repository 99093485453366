import React from 'react'
import {Link} from 'react-router-dom'
import Logo from './../../assets/img/logo.png'

function Sidebar() {
    
  return (
  
    <div className="sidebar" data-image="../assets/img/sidebar-5.jpg">
  
            <div className="sidebar-wrapper">
                <div className="logo">
                    <Link to="/" className="simple-text">
                        <img alt="logo" src={Logo}/>
                    </Link>
                </div>
                <ul className="nav">
                    <li>
                        <Link className="nav-link" to="/dashboard">
                            <i className="nc-icon nc-grid-45"></i>
                            <p>Home</p>
                        </Link>
                    </li>
                    <li>
                        <Link  className="nav-link" to="/categorias">
                            <i className="nc-icon nc-bullet-list-67"></i>
                            <p>Categorias</p>
                        </Link>
                    </li>

                    <li>
                        <Link className="nav-link" to="/posts">
                            <i className="nc-icon nc-notes"></i>
                            <p>Posts</p>
                        </Link>
                    </li>

                    <li>
                         <Link className="nav-link" to="/arquivos">
                            <i className="nc-icon nc-paper-2"></i>
                            <p>Arquivos</p>
                        </Link>
                    </li>
                

                    <li className="nav-item ">
                        <Link className="nav-link" to="/apartamentos">
                            <i className="nc-icon nc-layers-3"></i>
                            <p>Apartamentos</p>
                        </Link>
                    </li>

                    <li>
                        <Link className="nav-link" to="/usuarios">
                            <i className="nc-icon nc-circle-09"></i>
                            <p>Usuários</p>
                        </Link>
                    </li>

                    <li>
                    <Link className="nav-link" to="/logout">
                        <i className="nc-icon nc-button-power"></i>
                        <p>Sair do Sistema</p>
                    </Link>
                </li>
                   
                    
                </ul>
            </div>
        <div className="sidebar-background"   ></div></div>
  
  )
}

export default Sidebar;
