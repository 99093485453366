
import React, { useState, useEffect} from 'react'
import './../../assets/css/bootstrap.min.css'
import './../../assets/css/light-bootstrap-dashboard.css?v=2.0.0'
import './../../assets/css/demo.css'
import Sidebar from './../../components/Sidebar'
import SidebarClient from './../../components/Sidebar/client'
import NavbarTop from './../../components/NavbarTop'


function Layout(props) {

  const [provider, setProvider] = useState(null)

  useEffect(() => {
      checkIsLogged()
   },[])

  function checkIsLogged(){
      if(sessionStorage.getItem('userData')){
          const userData = JSON.parse(sessionStorage.getItem('userData'))
          setProvider(userData.data.user.provider)
      }
  }

  function SidebarMain() {

    if (provider === 0) {
      return <Sidebar />
    } else{
      return <SidebarClient />
    }
    
  }

  return (
  
    <div className="wrapper">
    
        <SidebarMain/>

        <div className="main-panel">
        <NavbarTop/>
            <div className="content">
                  <div className="container-fluid">
                      <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">{props.pageName}</h4>
                          </div>
                          <div className="card-body">
                              {props.children}
                          </div>
                      </div>
                 </div>
            </div>
        </div>
    </div>
  )
}

export default Layout
