import React from 'react'
import {Link} from 'react-router-dom'

const Panel = ({label, info, link, type, icon  })=>(
 
    <div className="col-lg-3 col-md-6">
    <div className={type}>
        <div className="panel-heading">
           
        <div className="col-xs-3">
            <i className={icon}></i>
        </div>
        <div className="col-xs-9 text-right">
            <div className="huge"> {info}</div>
            <div>{label}</div>
        </div>
           
        </div>
        <Link to={link}>
            <div className="panel-footer">
                <span className="pull-left">Detalhes</span>
                <span className="pull-right"><i className="fa fa-arrow-circle-right"></i></span>
                <div className="clearfix"></div>
            </div>
        </Link>
    </div>
</div>
    
) 



export default Panel