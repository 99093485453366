
import React, {useState, useEffect} from 'react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Layout from '../../components/layout'
import Upload from './../../components/Upload'
import Api from './../../services/Api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Files() {

  const [files, setFiles] = useState([])
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    getFiles()
  },[success]);

  async function getFiles(){
    const response = await Api.index('files')
    setFiles(response.data)
  }

  async function deleteFile(id){    
    try {
      await Api.delete('files', id)
      toast.success("Arquivo excluído com Sucesso !");
      setSuccess(true)
    } catch (error) {
      toast.error("Não foi possível excluir o arquivo");
    }
}

  function ButtonDelete  (cell, row, pageLink) {
    return <button onClick={()=>{deleteFile(row.id)}} type="button" 
    className={`btn btn-danger`}>
    <i className="fa fa-times-circle "></i> Excluir</button>
  }

  return (

        <Layout  pageName="Arquivos">

        <ToastContainer  />

          <Upload/>

              <BootstrapTable
                options={{noDataText:'Não existem arquivos cadastrados'}}
                data={files}
                pagination={ true }
                searchPlaceholder={"Filtrar"}
                search={ true }>
                <TableHeaderColumn  isKey={true} dataField='id'>Id</TableHeaderColumn>
                <TableHeaderColumn  dataField='file_name'>Nome da Arquivo</TableHeaderColumn>
                <TableHeaderColumn  dataField='button' dataFormat={ButtonDelete}>
                Excluir</TableHeaderColumn>

               </BootstrapTable>
        
        </Layout>

  )
}

export default Files