
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Layout from './../../components/layout'
import ButtonEdit from './../../components/ButtonEdit'
import Api from './../../services/Api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Apartaments() {

  const [apartments, setApartments] = useState([])
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    getApartaments()
  },[success]);

  async function getApartaments(){
    const response = await Api.index('apartments')
    setApartments(response.data)
  }

  async function deleteApartament(id){    

      try {
        await Api.delete('users', id)
        toast.success("Arquivo excluído com Sucesso !")
        setSuccess(true)
      } catch (error) {
        toast.error("Não foi possível excluir o apartamento")
      }
  }

  function ButtonDelete  (cell, row, pageLink) {
    return <button onClick={()=>{deleteApartament(row.id)}} type="button" 
    className={`btn btn-danger`}>
    <i className="fa fa-times-circle "></i> Excluir</button>
   }


  return (

        <Layout pageName="Apartamentos">

        <ToastContainer  />
        
        <Link  className="btn btn-info btn-fill" to="/apartamentos/novo">Cadastrar</Link>

              <BootstrapTable
                options={{noDataText:'Não existem apartamentos cadastradas'}}
                data={apartments}
                pagination={ true }
                searchPlaceholder={"Filtrar"}
                search={ true }>

                <TableHeaderColumn isKey={true} dataField='id'>Id</TableHeaderColumn>
                <TableHeaderColumn dataField='name'>Número do Apartamento</TableHeaderColumn>
               
                <TableHeaderColumn  
                dataField='button' 
                formatExtraData={'apartamentos'} 
                dataFormat={ButtonEdit} >Editar
              </TableHeaderColumn>
            
              <TableHeaderColumn  dataField='button' 
                dataFormat={ButtonDelete} >Excluir
                </TableHeaderColumn>
                 
               </BootstrapTable>
        
        </Layout>

  )
}

export default Apartaments