
import React,{useState} from 'react'
import Layout from './../../components/layout'
import Api from './../../services/Api'
import Alert from 'react-bootstrap/Alert'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup'

function UsersCreate() {

    const INITIAL_STAGE ={
        name: "", 
        email: "",
        provider:"",
        password:"",
        repeat_password:""
    }
    const [user, setUser] = useState(INITIAL_STAGE)
    const [success, setSuccess] = useState(false)

async function handleSubmit(e){
    e.preventDefault()

    try {
            
        const schema = Yup.object().shape({
            name: Yup.string().required('O nome do usuário é obrigatório'), 
            email: Yup.string().required('O email é obrigatório'), 
            password: Yup.string().required('Necessário o cadastro de senha'),
            repeat_password: Yup.string().oneOf([Yup.ref('password')], 'Senhas não são iguais')
            .required('Confirmação da senha é obrigatório')
        })
      
        await schema.validate(user, {
           abortEarly :false
        })
        saveUser()

    } catch (error) {

        error.inner.map((erro)=>{
            return toast.error(erro.message);
        })
        
    }

   
}

async function saveUser(){
    try {
        let data = user
        data.provider = 0;
        await Api.store('users', user)
        setSuccess(true)  
        toast.success("Usuário Salvo com Sucesso !");
        setUser(INITIAL_STAGE)
    } catch (error) {
        toast.error("Erro ao Salvar Usuário !");
    }  
}

function handleForm(e){
    const { name, value } = e.target;
    setUser(prevPost => ({
        ...prevPost,
        [name]: value
    }));      
}

  return (

        <Layout>

        <ToastContainer  />
        {
            success&& 
            <Alert closeLabel="Fechar"  variant="success">
                Usuário Cadastrado com Sucesso !!
            </Alert>
        }

        <div class="card-header create"><h4 class="card-title">Cadastrar Usuário</h4></div>

        <form name="contactform" className="contactform" onSubmit={handleSubmit}>  
            <div className="col-md-12">
                <div className="form-group">
                    <label>Nome </label>
                    <input type="text" onChange={handleForm}  value={user.name}  name="name" className="form-control" />
                </div>

                <div className="form-group">
                    <label>Email</label>
                    <input type="text" onChange={handleForm} name="email" value={user.email}  className="form-control" />
                 </div>

                <div className="form-group">
                    <label>Senha</label>
                    <input type="password" onChange={handleForm} value={user.password} name="password" className="form-control" />
                </div>

                <div className="form-group">
                    <label>Repetir Senha</label>
                    <input type="password" onChange={handleForm} value={user.repeat_password} name="repeat_password" className="form-control" />
                </div>

            
            </div>

            <button  className="btn btn-info btn-fill">Cadastrar</button>
       </form>
       
        </Layout>

  )
}

export default UsersCreate