import React, {useState, useContext, useEffect} from 'react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {ProgressBar , Modal , Button } from 'react-bootstrap';
import Api from './../../services/Api'
import {StoreContext} from './../../App'
import {  toast } from 'react-toastify';
import BASE_URL_UPLOAD from './../../services/EnvUpload'

function Upload (props){

    const [showModal, setShowModal] = useState(false)
    const [progress, setProgress] = useState(0)
    const [fileSelected, setFileSelected] = useState(null)
    const [files, setFiles] = useState([])
    const {fileUploaded, setFileUploaded, imageFeatured, setImageFeatured} =  useContext(StoreContext)
    const titleModal = (props.type === "imageFeatured")? "Anexar Imagem ": "Anexar Arquivo"
    const [success, setSuccess] = useState(false)
  
    useEffect(() => {
        getFiles()
      },[fileUploaded, imageFeatured, success]);

    async function getFiles(){
      const response = await Api.index('files')
      setFiles(response.data)
    }

    async function attachFile(file){    
      let dataFile = {}
     
      if(props.type === "imageFeatured" ){
        dataFile = {"id": file.id, "name": file.file_name, "type":"image"}
        setImageFeatured(dataFile);
      }else{
        dataFile = {"id": file.id, "name": file.file_name, "type":"file"}
        setFileUploaded(dataFile);
      }
    
    }
    
    function buttonAttach (cell, row, pageLink) {

      return <button onClick={()=>{attachFile(row)}} type="button" 
      className={`btn btn-secondary`}>
      <i className="fa fa-link "></i> Anexar</button>
     }

      function handleClose() {
        setShowModal(false)
      }
    
      function handleShow() {
        setProgress(0)
        setShowModal(true)
      }
  
      function fileSelectedHandler  (event) {
        setFileSelected(event.target.files[0])
     }

     async function uploadFile(file) {

        const config = {onUploadProgress: (ProgressEvent) => {
              let progressData = 0;
              progressData = Math.round((ProgressEvent.loaded ) / ProgressEvent.total * 100)
              setProgress(progressData)
            }
          }
            
          const response = await Api.uploadFile(file, config)
          const dataFile = {"id": response.data.id, "name": response.data.file_name}

          if(props.type === "imageFeatured" ){
            setImageFeatured(dataFile);
          }else{
            setFileUploaded(dataFile);
          }

          
      }
  
    function fileUploadHandler  () {
      const fd = new FormData()
      fd.append('anexo', fileSelected, fileSelected.name)
      uploadFile(fd)
   
   }

   function renderTitle(){
     const title = (props.type !== "imageFeatured" )? "Anexar Arquivo":"Anexar Imagem"
     return title
   }

   function buttoShowFile(cell, row){
    let disableButton = (row['file_name'])? null: 'disabled'
    let link = BASE_URL_UPLOAD + row['file_name']
  
     return <a target="_blank" rel="noopener noreferrer" href={link}    
      type="button" className={`btn btn btn-info ${disableButton}`}>
      <i className="fa fa-file"></i> Arquivo</a>
   }


      async function deleteFile(id){    
        try {
          await Api.delete('files', id)
          toast.success("Arquivo excluído com Sucesso !");
          setSuccess(true)
        } catch (error) {
          toast.error("Não foi possível excluir o arquivo");
        }
    }

      function ButtonDelete  (cell, row, pageLink) {
        return <button onClick={()=>{deleteFile(row.id)}} type="button" 
        className={`btn btn-danger`}>
        <i className="fa fa-times-circle "></i> Excluir</button>
      }
  
      return(
    
        <div >

              <Button className="btn btn-info btn-fill" variant="primary" onClick={handleShow}>{renderTitle()}</Button>
          
                <Modal className="modal-files-post" size="lg" show={showModal} onHide={handleClose}>
                    
                    <Modal.Header closeButton>
                       <Modal.Title>{titleModal}</Modal.Title>
                       
                    </Modal.Header>

                    <div className="row">
                      <div className="col-sm-11 ml-4"> 
                        {
                          (progress > 0) && <ProgressBar now={progress} label={`${progress}%`} />
                        }
                    </div>
                   </div>
               
                    <Modal.Body>
                        <input name="anexo" type="file" multiple onChange={fileSelectedHandler}></input>
                        

                    <div className="row">
                        <div className="col-sm-12"> 
                            <Button id="btn-send-file"  className="btn btn-info btn-fill" variant="primary" 
                            onClick={fileUploadHandler}>Enviar</Button>
                        </div>
                    </div>

                    <BootstrapTable 
                      options={{noDataText:'Não existem arquivos cadastrados',sizePerPage: 5}}
                      data={files}
                      pagination={ true }
                      searchPlaceholder={"Filtrar"}
                      search={ true }>
                    <TableHeaderColumn  isKey={true} dataField='id'>Id</TableHeaderColumn>
                    <TableHeaderColumn  dataField='file_name'>Nome da Arquivo</TableHeaderColumn>

                    <TableHeaderColumn  
                    dataField='button' 
                    dataFormat={buttonAttach} >
                    Anexar</TableHeaderColumn>

                    <TableHeaderColumn  
                    dataField='button' 
                    dataFormat={buttoShowFile}>Arquivo
                  </TableHeaderColumn>
              
              
                  <TableHeaderColumn  dataField='button' dataFormat={ButtonDelete}>
                  Excluir</TableHeaderColumn>
    
                   </BootstrapTable>
                  

                    </Modal.Body>
                    
                    <Modal.Footer>
                      {
                        (progress === 100) && 
                        <button id="btn-close-modal" className="btn btn-info waves-effect 
                        waves-light pull-right" 
                        onClick={handleClose}>Fechar</button>
                      }
                        
                    </Modal.Footer>
                
                </Modal>
          
          </div>
          
        )
    }


export default Upload





