import React, {useEffect, useState} from 'react'
import Layout from './../../components/layout'
import Sidebar from '../../components/Sidebar'
import Panel from './../../components/Panel'
import Api from './../../services/Api'

function Admin() {

  const [dashboard, setDashboard] = useState(false)

  useEffect(() => {
    getTotal()
  },[]);

  async function getTotal(){
    const response = await Api.index('dashboard-total')
    setDashboard(response.data)
  }


  
  function ShowNameUser(props) {

    if(sessionStorage.getItem('userData')){
      const userData = JSON.parse(sessionStorage.getItem('userData'))
      const name = userData.data.user.name
      return <strong>Você está logado como {name}</strong>
  }
 
  }

  return (
  
    <div className="wrapper">
        <Sidebar/>
        <Layout>
            <div className="alert alert-dash" role="alert">
              Bem Vindo Ao GestorFox. <ShowNameUser/>
            </div>
          
            <div className="row wrap-dashboard-panel">
                <Panel label="Apartamentos"  icon="nc-icon nc-layers-3 " info={dashboard.total_apartments}  link='/apartamentos'  type="panel panel-primary"> </Panel>
                <Panel label="Arquivos" icon="nc-icon nc-paper-2 " info={dashboard.total_files} link='/arquivos' type="panel panel-yellow"> </Panel>
                <Panel label="Posts"  icon="nc-icon nc-notes" info={dashboard.total_posts}  link='/posts'  type="panel panel-green"> </Panel>
                <Panel label="Usuários Cadastrados" icon="nc-icon nc-circle-09" info={dashboard.total_users}  link='/usuarios'  type="panel panel panel-red"> </Panel>
            </div>

        </Layout>

    </div>
  )
}

export default Admin
