import React, {createContext, useState} from 'react'
import {BrowserRouter as Router,  Route, Switch} from 'react-router-dom'
import Routes from './routes'
import Login from './pages/login'

export const StoreContext = createContext()

function App() {

  const INITIAL_STAGE_FILE ={"id": null,"name": null, "type":null}
  const [fileUploaded, setFileUploaded] = useState(INITIAL_STAGE_FILE)
  const [imageFeatured, setImageFeatured] = useState(INITIAL_STAGE_FILE)
  
  const INITIAL_STATE = {
    fileUploaded, 
    setFileUploaded,
    imageFeatured, 
    setImageFeatured
  }

  return (

        <StoreContext.Provider value={INITIAL_STATE}>
          <Router>
              <Switch> 
                <Route exact path="/" component={Login} /> 
                <Route exact path="/login" component={Login} /> 
                <Route exact path="/logout" component={Login} /> 
                <Route exact component={Routes}/> 
             </Switch>
          </Router>
        </StoreContext.Provider>
  
  )
}

export default App;
