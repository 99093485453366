
import React, {useState, useEffect} from 'react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {Link} from 'react-router-dom'
import Layout from '../../components/layout'
import Api from './../../services/Api'
import BASE_URL_UPLOAD from './../../services/EnvUpload'



function PostAllClient(props) {

  const [posts, setPosts] = useState([])
  
  useEffect(() => {
    getPosts()
  },[]);

  async function getPosts(id) {
    const response = await Api.index('posts')
    setPosts(response.data)
  }

  function ButtonDownload (cell, row, pageLink) {
    let disableButton = null
    let link = `/${pageLink}/single/`  
    return <Link to={link + row['id']} type="button" className={`btn btn btn-info ${disableButton}`}>
    <i className="fa fa-chevron-circle-down"></i> Ver Publicação</Link>
   }

   function buttoShowFile(cell, row){
    let disableButton = (row['file_name'])? null: 'disabled'
    let link = BASE_URL_UPLOAD + row['file_name']
  
     return <a target="_blank" rel="noopener noreferrer" href={link}    
      type="button" className={`btn btn btn-info ${disableButton}`}>
      <i className="fa fa-file"></i> Arquivo</a>
   }

   console.log('teste', posts)
 
  return (

        <Layout pageName="Publicações">

              <BootstrapTable
                options={{noDataText:'Não existem posts cadastrados'}}
                data={posts}
                pagination={ true }
                searchPlaceholder={"Filtrar"}
                search={ true }>
                <TableHeaderColumn isKey={true} dataField='id'>Id</TableHeaderColumn>
                <TableHeaderColumn  dataField='post_title'>Título do Post</TableHeaderColumn>

                <TableHeaderColumn  
                dataField='button' 
                dataFormat={buttoShowFile} >Arquivo
              </TableHeaderColumn>
                
                <TableHeaderColumn  
                  dataField='button' 
                  formatExtraData={'posts'} 
                  dataFormat={ButtonDownload}> Publicação
                </TableHeaderColumn>

              
          
               </BootstrapTable>

           

               
        
        </Layout>

  )
}

export default PostAllClient