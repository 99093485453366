import React from 'react';
import {Link} from 'react-router-dom'

function ButtonEdit  (cell, row, pageLink) {
 
  let disableButton = null
  let link = `/${pageLink}/edit/`  
   return <Link to={link + row['id']}    
    type="button" className={`btn btn btn-info ${disableButton}`}><i className="fa fa-chevron-circle-down"></i> Editar</Link>
 }

  export default ButtonEdit 