import React, { useState, useEffect} from 'react'
import {Switch, Route} from 'react-router-dom'
import Categories from './pages/categories'
import CategoriesCreate from './pages/categories/create'
import CategoriesEdit from './pages/categories/edit'
import CategoriesClient from './pages/categories/categories-client'
import Apartments from './pages/apartments'
import ApartmentsCreate from './pages/apartments/create'
import ApartmentsEdit from './pages/apartments/edit'
import Posts from './pages/publications'
import PostsCreate from './pages/publications/create'
import PostsEdit from './pages/publications/edit'
import PostsShowClient from './pages/publications/show-client'
import PostAllClient from './pages/publications/all-client'
import PostSingle from './pages/publications/single-post'
import Files from './pages/files'
import Users  from './pages/users'
import UsersCreate from './pages/users/create'
import UsersEdit from './pages/users/edit'
import Login from './pages/login'
import Dashboard from './pages/dashboard'
import DashboardClient from './pages/dashboard/client'

function Routes(){

    const [provider, setProvider] = useState(null)

    useEffect(() => {
        checkIsLogged()
     },[])

   

    function checkIsLogged(){
        if(sessionStorage.getItem('userData')){
            const userData = JSON.parse(sessionStorage.getItem('userData'))
            setProvider(userData.data.user.provider)
        }
    }


    if (provider === 0) {
       
      
    return(
        <Switch>
            
            <Route exact path="/dashboard" component={Dashboard} /> 
            <Route exact path="/categorias" component={Categories} /> 
            <Route exact path="/categorias/nova" component={CategoriesCreate} /> 
            <Route exact path="/categorias/edit/:id" component={CategoriesEdit} /> 
            <Route exact path="/apartamentos" component={Apartments} /> 
            <Route exact path="/apartamentos/novo" component={ApartmentsCreate} />
            <Route exact path="/apartamentos/edit/:id" component={ApartmentsEdit} /> 
            <Route exact path="/posts" component={Posts} /> 
            <Route exact path="/posts/novo" component={PostsCreate} /> 
            <Route exact path="/posts/edit/:id" component={PostsEdit} /> 
            <Route exact path="/arquivos" component={Files} /> 
            <Route exact path="/usuarios" component={Users} /> 
            <Route exact path="/usuarios/create" component={UsersCreate} /> 
            <Route exact path="/usuarios/edit/:id" component={UsersEdit} /> 
            <Route exact path="/login" component={Login} /> 
            <Route exact path="/logout" component={Login} /> 
        </Switch>
    )
    }else if (provider === 1){
        
        return(
            <Switch>
                <Route exact path="/dashboard" component={DashboardClient} /> 
                <Route exact path="/login" component={Login} /> 
                <Route exact path="/logout" component={Login} /> 
                <Route exact path="/posts" component={PostAllClient} /> 
                <Route exact path="/categorias" component={ CategoriesClient} /> 
                <Route exact path="/posts/categorias/:id" component={PostsShowClient} /> 
                <Route exact path="/posts/single/:id" component={PostSingle} /> 
           </Switch>

        )
    }else {
        
        return(
            <Switch>
                <Route exact path="/" component={Login} /> 
                <Route exact path="/login" component={Login} /> 
              
           </Switch>

        )
    }
}

export default Routes