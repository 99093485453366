import React from 'react'
import {Link} from 'react-router-dom'

const PanelImage = ({label, link, type,imgFeatured })=>(

   <div className="col-lg-4 col-md-6">
    <div className={type}>
        <div className=" panel-image">
            <img className="img-fluid" alt="Imagem Destacada" src={imgFeatured}/>
        </div>
        <Link to={link}>
            <div className="panel-footer-image">
                <span className="pull-left">Detalhes</span>
                <span className="pull-right"><i className="fa fa-arrow-circle-right"></i></span>
                <div className="clearfix"></div>
            </div>
        </Link>
    </div>
</div>
    
) 

export default PanelImage