
import React, {useState, useEffect} from 'react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {Link} from 'react-router-dom'
import ButtonEdit from './../../components/ButtonEdit'
import Api from './../../services/Api'
import Layout from '../../components/layout'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Users() {

  const [users, setUsers] = useState([])
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    getUsers()
  
  },[success]);

  async function getUsers(){
    const response = await Api.index('users')
    setUsers(response.data)
  }

  async function deleteUser(id){    

    try {
       await Api.delete('users', id)
       toast.success("Usuário excluído com Sucesso !")
       setSuccess(true)
    } catch (error) {
       toast.error("Não foi possível excluir o usuario")
    }
   
  }

  function ButtonDelete  (cell, row, pageLink) {
    return <button onClick={()=>{deleteUser(row.id)}} type="button" 
     className={`btn btn-danger`}>
    <i className="fa fa-times-circle "></i> Excluir</button>
   }

  return (

        <Layout pageName="Usuários">

        <ToastContainer  />

        <Link  className="btn btn-info btn-fill" to="/usuarios/create">Cadastrar</Link>
        
              <BootstrapTable
                options={{noDataText:'Não existem usuários cadastrados'}}
                data={users}
                pagination={ true }
                searchPlaceholder={"Filtrar"}
                search={ true }>

                <TableHeaderColumn isKey={true} dataField='id'>Id</TableHeaderColumn>
                <TableHeaderColumn dataField='name'>Nome do Usuário</TableHeaderColumn>
                
                <TableHeaderColumn  
                  dataField='button' 
                  formatExtraData={'usuarios'} 
                  dataFormat={ButtonEdit} >Editar
                </TableHeaderColumn>
            
              <TableHeaderColumn  dataField='button' 
               dataFormat={ButtonDelete} > Excluir
              </TableHeaderColumn>
              
                 
               </BootstrapTable>
        
        </Layout>

  )
}

export default Users