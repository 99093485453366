
import React, {useState, useEffect, useContext} from 'react'
import Layout from './../../components/layout'
import Upload from './files'
import {StoreContext} from './../../App'
import Api from './../../services/Api'
import { ToastContainer, toast } from 'react-toastify';
import Alert from 'react-bootstrap/Alert'
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup'

function EditPost(props) {

    const INITIAL_STAGE = {post_title: "", post_category_id: "",post_content:""}
    const INITIAL_STAGE_FILE ={"id": null,"name": null, "type":null}
    const [post, setPost] = useState([])
    const [categories, setCategories] = useState([])
    const [success, setSuccess] = useState(false)
    const id = props.match.params.id
    const {post_title, post_category_id, post_content} = post
    const {fileUploaded, setFileUploaded} =  useContext(StoreContext)
    const {imageFeatured, setImageFeatured} =  useContext(StoreContext)

    useEffect(() => {
        showPost(id)
    },[id]);

    useEffect(() => {
        post.post_image_id = imageFeatured.id
        setPost(post)
      },[imageFeatured]); 

     useEffect(() => {
        post.post_file_id = fileUploaded.id
        setPost(post)
      },[fileUploaded]);

      useEffect(() => {
        getCategories()
      
      },[]);

      
    useEffect(()=>{
     
        return function cleanFileUploaded() {
            setFileUploaded(INITIAL_STAGE_FILE)
        }

      },[]) 

      useEffect(()=>{
     
        return function cleanFileUploaded() {
            setImageFeatured(INITIAL_STAGE_FILE)
        }

      },[])
    
      async function getCategories(){
        const response = await Api.index('categories')
        setCategories(response.data)
      }

      function selectCategories(){
        return categories.map((category)=>{
          return <option key={category.id} value={category.id}>{category.category_title}</option>
        })
     
    }

    async function showPost(id){
        const response = await Api.show('post-single', id) 
        setPost(response.data)
     }
    
    async function updatePost(id){
        console.log('Atualizando', post)

        try {
            await Api.update('posts', id, post)
            toast.success("Post atualizado com sucesso !")
            setSuccess(true)  
        } catch (error) {
            return toast.error("Não foi possivel atualizar o post");
          
        }
      
     }

    async function handleSubmit(e){
        e.preventDefault()

        try {
            
            const schema = Yup.object().shape({
                post_title: Yup.string().required('O título do post é obrigatório'), 
                post_category_id: Yup.string().required('A categoria do post é obrigatório'),
                post_image_id: Yup.string().required('Uma Imagem de Destaque é obrigatória').nullable()
            })
             
            await schema.validate(post, {
               abortEarly :false
            })
            updatePost(id)
    
        } catch (error) {
            error.inner.map((erro)=>{
                return toast.error(erro.message);
            })
            
        }

       
    }

    function handleForm(e){
        const { name, value } = e.target;
        setPost(prevPost => ({
            ...prevPost,
            [name]: value
        }));      
    }

    function renderNameFile(){
        if(post){
            const nameFile = (!fileUploaded.name)?post.file_name:fileUploaded.name
            return nameFile
        } 
    }

    function renderNameImage(){
        if(post){
            const nameImage = (!imageFeatured.name)?post.image_name:imageFeatured.name
            return nameImage
        } 
    }

    

  return (

        <Layout>

        <ToastContainer  />
        {
            success&& 
            <Alert closeLabel="Fechar"  variant="success">
                Post Atualizado com Sucesso !!
            </Alert>
        }

        <div className="card-header create"><h4 className="card-title">Editar Post</h4></div>

        <form name="contactform" className="contactform" onSubmit={handleSubmit}>    
            <div className="col-md-12">
                <div className="form-group">
                    <label>Título do Post</label>
                    <input onChange={handleForm}  defaultValue={post_title}  
                    type="text" name="post_title" className="form-control" />
                </div>

                <div className="form-group">
                    <label>Selecione a Categoria</label>
                    <select onChange={handleForm}  value={post_category_id}  
                    name="post_category_id"  className="form-control">
                    {selectCategories()}
                </select>
                </div>

                <div className="form-group">
                    <label>Conteúdo</label>
                    <textarea onChange={handleForm} name="post_content" defaultValue={post_content}   
                    className="form-control" />
                </div>

            <div className="form-group">
                <label>Anexar Arquivo</label>
                <div className="col-md-12"> 
                    <div className="row">
                        <div className="col-md-6"> 
                            <input disabled onChange={handleForm} 
                            defaultValue={renderNameFile()} type="text" 
                            name="file_name" className="form-control" />
                        </div>
                        <div className="col-md-3"> 
                           <Upload/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="form-group">
            <label>Anexar Imagem de Destaque</label>
            <div className="col-md-12"> 
                <div className="row">
                    <div className="col-md-6"> 

                        <input disabled onChange={handleForm} defaultValue={renderNameImage()} type="text" 
                        name="file_name" className="form-control" />
                    </div>
                    <div className="col-md-3"> 
                       <Upload type="imageFeatured" />
                    </div>
                </div>
            </div>
        </div>
                
                <button  className="btn btn-info btn-fill">Atualizar</button>


            </div>
       
            </form> 
        </Layout>

  )
}

export default EditPost