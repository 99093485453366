
import React, {useEffect, useState} from 'react'
import Layout from './../../components/layout'
import Api from './../../services/Api'
import { ToastContainer, toast } from 'react-toastify';
import Alert from 'react-bootstrap/Alert'
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup'

function EditCategory(props) {

const [categoryEdit, setCategoryEdit] = useState({});
const [success, setSuccess] = useState(false)
const {category_title, category_obs} = categoryEdit
const id = props.match.params.id

useEffect(() => {
    showCategory(id)
  },[id]);

 async function showCategory(id){
     const response = await Api.show('categories', id) 
     setCategoryEdit(response.data)
 }

 async function updateCategory(id){

    try {
        let category = categoryEdit
        category._method = "PUT"
        await Api.update('categories', id, category)
        setSuccess(true)  
        toast.success("Categoria Atualizada com Sucesso !")
        
    } catch (error) {
        toast.error("Não foi possivel atualizar categoria");
        console.log(error)
        
    }

 }

  async function handleSubmit(e){
    e.preventDefault()
    console.log(categoryEdit)
  
    try {
            
        const schema = Yup.object().shape({
            category_title: Yup.string().required('O nome da categoria é obrigatório'),  
        })
         
        await schema.validate(categoryEdit, {
           abortEarly :false
        })
       updateCategory(id)

    } catch (error) {
        toast.error(error.inner[0].message);
    }

}

function handleForm(e){
    const { name, value } = e.target;
    setCategoryEdit(prevPost => ({
        ...prevPost,
        [name]: value
    }));      
}

  return (

        <Layout>

        <ToastContainer  />
            {
                success&& 
                <Alert closeLabel="Fechar"  variant="success">
                    Categoria Atualizada com Sucesso !!
                </Alert>
            }

            <form name="contactform" className="contactform" onSubmit={handleSubmit}>    
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Nome da Categoria</label>
                        <input onChange={handleForm} defaultValue={category_title}  
                        name="category_title" type="text" className="form-control" />
                    </div>

                    <div className="form-group">
                        <label>Observações</label>
                        <textarea onChange={handleForm} 
                        defaultValue={category_obs} name="category_obs"   className="form-control" />
                    </div>

                    <button  className="btn btn-info btn-fill">Atualizar</button>

                </div>
       
            </form> 
        </Layout>

  )
}



export default EditCategory



