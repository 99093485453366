
import React, {useState, useEffect, useContext} from 'react'
import Layout from './../../components/layout'
import Alert from 'react-bootstrap/Alert'
import Upload from './files'
import Api from './../../services/Api'
import {StoreContext} from './../../App'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup'

function CreatePost() {

    const INITIAL_STAGE = {post_title: "", post_category_id: "",post_content:""}
    const INITIAL_STAGE_FILE ={"id": null,"name": null, "type":null}
    const [post, setPost] = useState(INITIAL_STAGE)
    const [success, setSuccess] = useState(false)
    const [categories, setCategories] = useState([])
    const {fileUploaded, setFileUploaded} =  useContext(StoreContext)
    const {imageFeatured, setImageFeatured} =  useContext(StoreContext)
         
    useEffect(() => {
        getCategories()
      },[success]);

      useEffect(() => {
        post.post_image_id = imageFeatured.id
        setPost(post)

      },[imageFeatured, post]);

      useEffect(() => {
        post.post_file_id = fileUploaded.id
        setPost(post)

      },[fileUploaded, post]);


      useEffect(()=>{
     
        return function cleanFileUploaded() {
            setFileUploaded(INITIAL_STAGE_FILE)
        }

      },[]) 

      useEffect(()=>{
     
        return function cleanFileUploaded() {
            setImageFeatured(INITIAL_STAGE_FILE)
        }

      },[])

      async function getCategories(){
        const response = await Api.index('categories')
        setCategories(response.data)
      }

      function selectCategories(){
          return categories.map((category)=>{
            return <option key={category.id} value={category.id}>{category.category_title}</option>
          })
       
      }

    async function handleSubmit(e){
        e.preventDefault()
    
        try {
            
            const schema = Yup.object().shape({
                post_title: Yup.string().required('O título do post é obrigatório'), 
                post_category_id: Yup.string().required('A categoria do post é obrigatório'),
                post_image_id: Yup.string().required('Uma Imagem de Destaque é obrigatória').nullable()
            })
             
            await schema.validate(post, {
               abortEarly :false
            })
            savePost()
    
        } catch (error) {
            error.inner.map((erro)=>{
                return toast.error(erro.message);
            })
        }
    }

    async function savePost(){
        try {
            await Api.store('posts', post)
            toast.success("Post criado com sucesso !");
            setPost(INITIAL_STAGE)
            setImageFeatured(INITIAL_STAGE_FILE)
            setFileUploaded(INITIAL_STAGE_FILE)
            setSuccess(true) 
        } catch (error) {
            toast.error("Erro ao criar novo post !");
        }
    }

    function handleForm(e){
        const { name, value } = e.target;
        setPost(prevPost => ({
            ...prevPost,
            [name]: value
        }));      
    }

   

  return (

        <Layout>

        <ToastContainer  />

        {
            success&& 
            <Alert closeLabel="Fechar"  variant="success">
                Post Cadastrado com Sucesso !!
            </Alert>
        }

        <div className="card-header create"><h4 className="card-title">Cadastrar Post</h4></div>

        <form name="contactform" className="contactform" onSubmit={handleSubmit}>    
            <div className="col-md-12">
                <div className="form-group">
                    <label>Título do Post</label>
                    <input onChange={handleForm} value={post.post_title}  type="text" name="post_title" className="form-control" />
                </div>

                <div className="form-group">
                    <label>Selecione a Categoria</label>
                    <select value={post.post_category_id} onChange={handleForm} name="post_category_id"  className="form-control">
                    <option value="0">Selecione Categoria </option>
                        {selectCategories()}
                </select>
                </div>

                <div className="form-group">
                    <label>Conteúdo</label>
                    <textarea onChange={handleForm} value={post.post_content} name="post_content"   className="form-control" />
                </div>

                <div className="form-group">
                    <label>Anexar Arquivo</label>
                    <div className="col-md-12"> 
                        <div className="row">
                            <div className="col-md-6"> 
                                <input disabled onChange={handleForm} defaultValue={fileUploaded.name} type="text" 
                                name="file_name" className="form-control" />
                            </div>
                            <div className="col-md-3"> 
                               <Upload/>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="form-group">
                <label>Anexar Imagem de Destaque</label>
                <div className="col-md-12"> 
                    <div className="row">
                        <div className="col-md-6"> 

                            <input disabled onChange={handleForm} defaultValue={imageFeatured.name} type="text" 
                            name="file_name" className="form-control" />
                        </div>
                        <div className="col-md-3"> 
                           <Upload type="imageFeatured" />
                        </div>
                    </div>
                </div>
            </div>

                
                <button  className="btn btn-info btn-fill">Cadastrar</button>


            </div>
       
            </form> 
        </Layout>

  )
}

export default CreatePost