
import React, {useState} from 'react'
import Layout from './../../components/layout'
import Api from './../../services/Api'
import Alert from 'react-bootstrap/Alert'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup'

function ApartmentCreate() {

const INITIAL_STAGE ={
    name: "", 
    email: "",
    provider:"",
    password:"",
    repeat_password:""
}

const [apartment, setApartment] = useState(INITIAL_STAGE)
const [success, setSuccess] = useState(false)

async function handleSubmit(e){
    e.preventDefault()

    try {
            
        const schema = Yup.object().shape({
            name: Yup.string().required('O número do apartamento é obrigatório'), 
            password: Yup.string().required('Necessário o cadastro de senha'),
            repeat_password: Yup.string().oneOf([Yup.ref('password'), null], 'Senhas devem ser iguais')
        })
      
       
        await schema.validate(apartment, {
           abortEarly :false
        })
        saveApartment()

    } catch (error) {
     
        error.inner.map((erro)=>{
            return toast.error(erro.message);
        })
        
    }

  
}

async function saveApartment(){
    try {
        let data = apartment
        data.provider = 1;
        data.email = data.name
        await Api.store('users', data)
        setSuccess(true)  
        toast.success("Apartamento Salvo com Sucesso !");
        setApartment(INITIAL_STAGE)
    } catch (error) {
        toast.error("Erro ao Salvar Apartamento !");
    }
  
}

function handleForm(e){
    const { name, value } = e.target;
    setApartment(prevPost => ({
        ...prevPost,
        [name]: value
    }));      
}

  return (

        <Layout>

        <ToastContainer  />
        {
            success&& 
            <Alert closeLabel="Fechar"  variant="success">
                Apartamento Cadastrado com Sucesso !!
            </Alert>
        }

            <form name="contactform" className="contactform" onSubmit={handleSubmit}>    
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Número do Apartamento</label>
                        <input type="text" onChange={handleForm} name="name" className="form-control" />
                    </div>

                    <div className="form-group">
                        <label>Senha</label>
                        <input type="password" onChange={handleForm} name="password" className="form-control" />
                    </div>

                    <div className="form-group">
                        <label>Repetir Senha</label>
                        <input type="password" onChange={handleForm} name="repeat_password" className="form-control" />
                    </div>

                    <button  className="btn btn-info btn-fill">Cadastrar</button>
                </div>
          
            </form>
        </Layout>

  )
}

export default ApartmentCreate