
import React, {useState, useEffect} from 'react'
import Layout from './../../components/layout'
import Api from './../../services/Api'
import BASE_URL_UPLOAD from './../../services/EnvUpload'

function SinglePost(props) {

    const [post, setPost] = useState([])
    const id = props.match.params.id
    const {post_title, post_category_id, post_content, file_name} = post
    const linkFile= BASE_URL_UPLOAD+file_name

    useEffect(() => {
        showPost(id)
    },[id]);

    async function showPost(id){
        const response = await Api.show('post-single', id) 
        setPost(response.data)
     }
    
   
   function ButtonDownload(){
       if(file_name === undefined){
           return(
            <div className="col-md-3"> 
           </div>
           )
       } else if(file_name === null){
        return(
            <div className="col-md-12"> 
            <div className="alert alert-dash" 
                role="alert">Não existe arquivo anexado ao post! </div>
           </div>
           )
       } else{
        return(
        <div className="col-md-3"> 
            <a target="_blank" rel="noopener noreferrer" href={linkFile} 
             className="btn btn-info btn-fill form-control" >Baixar Arquivo</a>
         </div>
         )
       }
   }  
     
  return (

        <Layout>

        <div className="card-header create"><h4 className="card-title">Publicação</h4></div>

        <form name="contactform" className="contactform" >    
            <div className="col-md-12">
                <div className="form-group">
                    <label>Título do Post</label>
                    <input disabled   defaultValue={post_title}  
                    type="text" name="post_title" className="form-control" />
                </div>

                <div className="form-group">
                    <label>Selecione a Categoria</label>
                    <select disabled   value={post_category_id}  
                    name="post_category_id"  className="form-control">
                    <option value="0">Selecione Categoria </option>
                    <option value="1">Documentos</option>
                </select>
                </div>

                <div className="form-group">
                    <label>Conteúdo</label>
                    <textarea disabled  name="post_content" defaultValue={post_content}   
                    className="form-control" />
                </div>

                <div className="form-group">
                    <label> Arquivo</label>
                    <div className="col-md-12"> 
                        <div className="row">
                            <ButtonDownload/>
                        </div>
                    </div>
                </div>
                
            </div>
       
            </form> 
        </Layout>

  )
}

export default  SinglePost