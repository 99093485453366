
import React, {useEffect, useState} from 'react'
import Layout from './../../components/layout'
import Api from './../../services/Api'
import { ToastContainer, toast } from 'react-toastify';
import Alert from 'react-bootstrap/Alert'
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup'

function UserEdit(props) {

    const [user, setUser] = useState([]);
    const [success, setSuccess] = useState(false)
    const {name, email} = user
    const id = props.match.params.id

    useEffect(() => {
        showUser(id)
    },[id]);

    async function showUser(id){
        const response = await Api.show('users', id) 
        setUser(response.data)
    }

    async function updateUser(id){
        try {
            await Api.update('users', id, user)
            toast.success("'Usuário Atualizado com Sucesso !")
            setSuccess(true)  
        } catch (error) {
            
        }
      
    }

    async function handleSubmit(e){
        e.preventDefault()
        try {
            
            const schema = Yup.object().shape({
                name: Yup.string().required('O nome do usuário é obrigatório'), 
                email: Yup.string().required('O email é obrigatório')
              
            })
          
            await schema.validate(user, {
               abortEarly :false
            })
            updateUser(id)
    
        } catch (error) {
    
            error.inner.map((erro)=>{
                return toast.error(erro.message);
            })
            
        }

        
    }

    function handleForm(e){
        const { name, value } = e.target;
        setUser(prevPost => ({
            ...prevPost,
            [name]: value
        }));      
    }

  return (

        <Layout>

        <ToastContainer  />
        {
            success&& 
            <Alert closeLabel="Fechar"  variant="success">
                Usuário Atualizado com Sucesso !!
            </Alert>
        }

        <div class="card-header create"><h4 class="card-title">Atualizar Usuário</h4></div>

        <form name="contactform" className="contactform" onSubmit={handleSubmit}>    
            <div className="col-md-12">
                <div className="form-group">
                    <label>Nome </label>
                    <input type="text" onChange={handleForm} defaultValue={name} 
                    name="name" className="form-control" />
                </div>

                <div className="form-group">
                    <label>Email</label>
                    <input type="text" defaultValue={email}  onChange={handleForm}
                     name="email" className="form-control" />
                </div>

                
                <div className="form-group">
                    <label>Senha</label>
                    <input type="password" onChange={handleForm} value={user.password} name="password" className="form-control" />
                </div>

                <div className="form-group">
                    <label>Repetir Senha</label>
                    <input type="password" onChange={handleForm} value={user.repeat_password} name="repeat_password" className="form-control" />
                </div>

                <button  className="btn btn-info btn-fill">Atualizar</button>
            </div>
       
       </form>
        </Layout>

  )
}

export default UserEdit