
import React, {useState} from 'react'
import Layout from './../../components/layout'
import Alert from 'react-bootstrap/Alert'
import Api from './../../services/Api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup'



function CreateCategory(props) {

const INITIAL_STAGE ={
    category_title: "", 
    category_obs: ""
}
const [category, setCategory] = useState(INITIAL_STAGE)
const [success, setSuccess] = useState(false)

async function handleSubmit(e){
    e.preventDefault()

    try {
            
        const schema = Yup.object().shape({
            category_title: Yup.string().required('O nome da categoria é obrigatório'),  
        })
         
        await schema.validate(category, {
           abortEarly :false
        })
        saveCategory()

    } catch (error) {
        toast.error(error.inner[0].message);
    }

}

async function saveCategory(){
  
    try {
        await Api.store('categories', category)
        setSuccess(true)  
        toast.success("Categoria Salva com Sucesso !");
        setCategory(INITIAL_STAGE)
    } catch (error) {
        
    }
}

function handleForm(e){
    const { name, value } = e.target;
    setCategory(prevPost => ({
        ...prevPost,
        [name]: value
    }));      
}

  return (

        <Layout>

        <ToastContainer  />
            {
                success&& 
                <Alert closeLabel="Fechar"  variant="success">
                    Categoria Cadastrada com Sucesso !!
                </Alert>
            }

    
        <div className="card-header create"><h4 className="card-title">Cadastrar Categoria</h4></div>

            <form name="contactform" className="contactform" onSubmit={handleSubmit}>    

                <div className="col-md-12">
                    <div className="form-group">
                        <label>Nome da Categoria</label>
                        <input onChange={handleForm} value={category.category_title}  name="category_title"
                         type="text" className="form-control" />
                    </div>

                    <div className="form-group">
                        <label>Observações</label>
                        <textarea onChange={handleForm} value={category.category_obs} name="category_obs"   
                        className="form-control" />
                    </div>

                    <button  className="btn btn-info btn-fill">Cadastrar</button>

                </div>
       
            </form> 
        </Layout>

  )
}


export default CreateCategory 



