import React, {useEffect, useState} from 'react'
import './../../assets/css/bootstrap.min.css'
import './../../assets/css/light-bootstrap-dashboard.css?v=2.0.0'
import './../../assets/css/demo.css'
import Layout from './../../components/layout'
import SidebarClient from '../../components/Sidebar/client'
import Api from './../../services/Api'
import PanelImage from '../../components/PanelImage'
import BASE_URL_UPLOAD from './../../services/EnvUpload'

function DashboardCliente() {

  const [posts, setPosts] = useState([])
  
  useEffect(() => {
    getPosts()
  },[]);

  async function getPosts(id) {
    const response = await Api.index('posts-home-client')
    setPosts(response.data)
  }

  function ShowNameUser() {

    if(sessionStorage.getItem('userData')){
      const userData = JSON.parse(sessionStorage.getItem('userData'))
      const name = userData.data.user.name
      return <span>Você está logado como <strong> Apartamento {name} </strong> </span>
  }
}

  const listItems = posts.map((post) =>
  <PanelImage label="Manual do Prop" 
    imgFeatured={BASE_URL_UPLOAD+post.file_name} link={`/posts/single/${post.id}`}
    type="panel panel-primary"> 
  </PanelImage>
  );

  return (
  
    <div className="wrapper">
        <SidebarClient/>
        <Layout>
            <div className="alert alert-dash" role="alert">
              Bem Vindo Ao <strong> GestorFox !</strong> <ShowNameUser/>
            </div>
          
            <div className="row wrap-dashboard-panel">{listItems}</div>
          
        </Layout>

    </div>
  )

  }
export default DashboardCliente

